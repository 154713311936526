import { v4 } from "uuid"
import CryptoJS from "react-native-crypto-js"
import freeEmailDomains from "free-email-domains"

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () => {
  try {
    return isBrowser() && getFromStorage("user") ? getFromStorage("user") : {}
  } catch (e) {
    throw e
  }
}
export const isLoggedIn = () => {
  const user = getUser()

  return Object.keys(user).length
}
export const readFromStorage = async (callback = () => {}) => {
  try {
    const profileString = getFromStorage("profile")
    const profile = JSON.parse(profileString || "{}")
    const userString = getFromStorage("user")
    const user = JSON.parse(userString || "{}")
    const currentPurchaseString = localStorage.getItem("currentPurchase")
    const currentPurchaseLS = JSON.parse(currentPurchaseString || "{}")
    if (Object.keys(user).length === 0 || Object.keys(profile).length === 0) {
      console.warn("warning: user or profile are empty")
      return false
    }
    const result = { user, profile, currentPurchaseLS }
    callback instanceof Function && callback(result)
    return result
  } catch (error) {
    console.error("error: ", error)
    return false
  }
}
export const getFromStorage = key => {
  const hash = typeof window !== "undefined" && localStorage.getItem(key)
  if (typeof hash === "string") {
    var bytes = CryptoJS.AES.decrypt(hash, key)

    var decryptedData = bytes?.toString(CryptoJS.enc.Utf8)
    return decryptedData
  }
}
export const setToStorage = async (key, data) => {
  const hash = CryptoJS.AES.encrypt(JSON.stringify(data), key).toString()
  typeof window !== "undefined" && localStorage.setItem(key, hash)
}

export const hasFreeDomain = (email = "") => {
  let mailDomain = ""
  try {
    const formattedMail = email.replace("@", ".")
    const emailParts = formattedMail.split(".")
    mailDomain = `${emailParts[emailParts.length - 2]}.${
      emailParts[emailParts.length - 1]
    }`
  } catch (error) {
    console.warn("Not a valid email address format")
    return true
  }

  return freeEmailDomains.includes(mailDomain)
}
